<template>
  <div class="home" @wheel="scroll" @touchmove="touchmove" @touchstart="touchstart">
    <!-- <Background
      :src="require('../assets/background.png')"
      :y="30 + index * 10"
      :z="-3"
      :opacity="1" /> -->
    <Background
      src="https://9p2jla.bn.files.1drv.com/y4m839XskS6vs7tOaHUFrBgBxpQaH3-NoI6pSL4QhWOXQKu4EVYlYEuuDDY0QRQEaCozxb7cjdYpA_Hj7HXfYKyAEwWXaSMGIhIB2wU9fqbzKIcyyk8_K0_SqqThCGM3lPM6kNd--SuAWbOxXR5Oi_QEQ9TzHD6-PH4wsfcjzJIyucG1JGe94luVyf9aZfVdEUhw_Qc0Bs9QhlNcXKa277yMA?width=984&height=720&cropmode=none"
      :y="30 + index * 10"
      :z="-2"
      :opacity="1" />
    <WhiteBack :isActive="true" />
    <div class="items" :style="{ top }" ref="items">
      <component
        v-for="(section, i) in sections"
        :is="section"
        :prevent="prevent"
        :v-if="Math.abs(index - i) <= 1"
        :key="i"
        :style="{ top: `${i * 100}%` }" />
    </div>
    <SectionGuide
      :length="sections.length"
      :index="index"
      :jump="jump" />
    <HomeButton />
  </div>
</template>

<script>
// @ is an alias to /src
import { publish } from '@/lib/publisher'
import ProgramSection from '@/components/ProgramSection'
import FooterSection from '@/components/FooterSection'

import Background from '@/components/Background'
import WhiteBack from '@/components/WhiteBack'
import SectionGuide from '@/components/SectionGuide'
import HomeButton from '@/components/HomeButton'
import scrollMixin from '@/mixins/scrollMixin'

let beforeScrollTop = 0
let beforeScrollTarget = null

export default {
  name: 'access',
  mixins: [scrollMixin],
  components: {
    ProgramSection,
    FooterSection,
    Background,
    WhiteBack,
    SectionGuide,
    HomeButton
  },
  metaInfo: {
    title: 'プログラム | 第73回都立西高校記念祭'
  },
  data() {
    return {
      sections: [
        'ProgramSection',
        'FooterSection'
      ],
      length: 0
    }
  },
  computed: {
    top() {
      return `${-this.index * 100}%`
    }
  },
  mounted() {
    setTimeout(() => {
      publish('loaded')
      publish('updateKagikakko', 'kagi-program')
    }, 500)
  },
  watch: {
    index() {
      switch (this.sections[this.index]) {
        case 'ProgramSection':
          publish('updateKagikakko', 'kagi-program')
          break
        case 'ThemeSection':
          publish('updateKagikakko', 'kagi-description')
          break
      }
    }
  }
}
</script>

<style>
.home {
  /* background-image: url('../assets/background.png'); */
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.items {
  position: relative;
  transition: top .7s;
  height: 100%;
}

section {
  width: 100vw;
  height: 100%;
  position: absolute;
  box-sizing: border-box;
  text-align: center;
}

section h2 {
  font-size: 50px;
  height: 23%;
  /* line-height: 20vh; */
  font-weight: normal;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
section article {
  text-align: left;
  padding: 0 10px;
  font-family: 'Noto Serif JP';
  max-width: 600px;
  margin: auto;
  overflow-x: auto;
  height: 77vh;
}
</style>
