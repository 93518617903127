<template>
  <div class="home">
    <Background
      src="https://9p2lla.bn.files.1drv.com/y4mKWvxLEsMKpsGatmL6MSJde3MLLCMX8O0mYzLercZCE1F3J4Ba4ty53Z8FOcnprjQj-cyBm4YearFf8_RrgDzN9KkCzQNL9RUlMiZACXR_4qLLImHvsOHoUcf5z86SD0Mlkm1ZNlB4YbhAmnvs8Zj0Sm-jrJL7KIPHruZYs-MSdv3H8yb7oAXDzJyKxsiTH_fQn4aA1_0eQGCGqozOorNyA?width=1536&height=1125&cropmode=none"
      :y="30"
      :z="-3"
      :opacity="1"
      :onload="onload" />
    <WhiteBack :isActive="true" />
    <div class="items" ref="items">
      <section>
        <h2>Time Table</h2>
        <h3>
          タップまたはクリックで拡大
        </h3>
        <article>
          １日目
          <img :src="urls[0]" @click="zoom(0)" />
          ２日目
          <img :src="urls[1]" @click="zoom(1)" />
        </article>
      </section>
    </div>
    <HomeButton />
  </div>
</template>

<script>
// @ is an alias to /src
import { publish } from '@/lib/publisher'

import Background from '@/components/Background'
import WhiteBack from '@/components/WhiteBack'
import HomeButton from '@/components/HomeButton'

let loadedCount = 0

export default {
  name: 'time-table',
  components: {
    Background,
    WhiteBack,
    HomeButton
  },
  metaInfo: {
    title: 'タイムテーブル | 第73回都立西高校記念祭'
  },
  data() {
    return {
      urls: [
        'https://9u2jla.bn.files.1drv.com/y4mq3IzfpAYmgUHC8PivsdIOBJdO7qNbi6bo-XJ9ccoQPPCIL-LHgZH3igJcK3pyXDcaLo_R5vxAqqcyy7v2y_iCkdpeOjG7vbxw2Do9xgT4lpddlOyMB_o9Y0QWGC836ef0XqXRidjKOd1R_3aJiQaOvehpKTEnDDP0kmjsI-lTYcrSFolNkxFemCgi1bpKkEtEiGZ_L2CiT7OEsEsS7da9w?width=660&height=415&cropmode=none',
        'https://9u2ila.bn.files.1drv.com/y4mplwbdz4CmJO4KFOeE62NQt2wE4sVZX1toJ_x7-4s6QetiYlvKK3qvkyj_r0lrWZkhq0r6qKtx0Nwzri3zOEhpD1pTwmsFAvTO3bEb5doYf5ecfMKHrJFj5iGsyLceRhGei6I8WrtUgVs_6SONvcmwGwLnpDhYzys3KTDuRhRqoQeJCmyDn-jy9tLrzOtTxmUuOGqrLTIaaLPcNigk97VoA?width=660&height=424&cropmode=none'
      ],
      largeUrls: [
        'https://9u2jla.bn.files.1drv.com/y4mq3IzfpAYmgUHC8PivsdIOBJdO7qNbi6bo-XJ9ccoQPPCIL-LHgZH3igJcK3pyXDcaLo_R5vxAqqcyy7v2y_iCkdpeOjG7vbxw2Do9xgT4lpddlOyMB_o9Y0QWGC836ef0XqXRidjKOd1R_3aJiQaOvehpKTEnDDP0kmjsI-lTYcrSFolNkxFemCgi1bpKkEtEiGZ_L2CiT7OEsEsS7da9w?width=2658&height=1672&cropmode=none',
        'https://9u2ila.bn.files.1drv.com/y4mplwbdz4CmJO4KFOeE62NQt2wE4sVZX1toJ_x7-4s6QetiYlvKK3qvkyj_r0lrWZkhq0r6qKtx0Nwzri3zOEhpD1pTwmsFAvTO3bEb5doYf5ecfMKHrJFj5iGsyLceRhGei6I8WrtUgVs_6SONvcmwGwLnpDhYzys3KTDuRhRqoQeJCmyDn-jy9tLrzOtTxmUuOGqrLTIaaLPcNigk97VoA?width=2512&height=1614&cropmode=none'
      ]
    }
  },
  methods: {
    onload() {
      loadedCount++
      if (loadedCount >= 3) {
        publish('loaded')
        publish('updateKagikakko', 'kagi-time-table')
      }
    },
    changeCurrentDate(currentDate) {
      this.currentDate = currentDate
    },
    zoom(index) {
      window.open(this.largeUrls[index])
    }
  },
  mounted() {
    const imageDay1 = new Image()
    imageDay1.src = this.urls[0]
    imageDay1.onload = () => {
      this.onload()
    }
    const imageDay2 = new Image()
    imageDay2.src = this.urls[1]
    imageDay2.onload = () => {
      this.onload()
    }
  }
}
</script>

<style scoped>
.home {
  /* background-image: url('../assets/background.png'); */
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.items {
  position: relative;
  transition: top .7s;
  height: 100%;
}

section {
  width: 100vw;
  height: 100%;
  position: absolute;
  box-sizing: border-box;
  text-align: center;
}

section h2 {
  font-size: 50px;
  height: 23%;
  /* line-height: 20vh; */
  font-weight: normal;
  padding: 0;
  margin: 0;
  display: flex;
  align-content: center;
  justify-content: center;
}
section h3 {
  margin: 0;
  margin-top: -20px;
  line-height: 110%;
  font-weight: normal;
}
section article {
  margin: 10px;
  font-family: 'Noto Serif JP';
  height: calc(77% - 20px);
  box-sizing: border-box;
  width: calc(100% - 20px);
  max-width: none;
  overflow: hidden;
  padding: 0;
  text-align: center;
}
.inactive-date {
  text-decoration: underline;
}
img {
  display: block;
  max-height: calc(50% - 16px);
  margin: 0 auto;
  max-width: 100%;
}
</style>
