<template>
  <div class="home">
    <Background
      src="https://9p2lla.bn.files.1drv.com/y4mKWvxLEsMKpsGatmL6MSJde3MLLCMX8O0mYzLercZCE1F3J4Ba4ty53Z8FOcnprjQj-cyBm4YearFf8_RrgDzN9KkCzQNL9RUlMiZACXR_4qLLImHvsOHoUcf5z86SD0Mlkm1ZNlB4YbhAmnvs8Zj0Sm-jrJL7KIPHruZYs-MSdv3H8yb7oAXDzJyKxsiTH_fQn4aA1_0eQGCGqozOorNyA?width=1536&height=1125&cropmode=none"
      :y="30"
      :z="-3"
      :opacity="1"
      :onload="onload" />
    <WhiteBack :isActive="true" />
    <div class="items" ref="items">
      <section>
        <h2>Map</h2>
        <h3>
          <span
            :class="{ 'inactive-date': currentDate !== 1 }"
            @click="changeCurrentDate(1)">
            １階
          </span>
          &nbsp;/&nbsp;
          <span
            :class="{ 'inactive-date': currentDate !== 2 }"
            @click="changeCurrentDate(2)">
            ２階
          </span>
          &nbsp;/&nbsp;
          <span
            :class="{ 'inactive-date': currentDate !== 3 }"
            @click="changeCurrentDate(3)">
            ３階
          </span>
          <br />
          タップまたはクリックで拡大
        </h3>
        <article>
          <img @click="zoom" :src="urls[currentDate - 1]" />
        </article>
      </section>
    </div>
    <HomeButton />
  </div>
</template>

<script>
// @ is an alias to /src
import { publish } from '@/lib/publisher'
import schoolMap from '@/lib/schoolMap'

import Background from '@/components/Background'
import WhiteBack from '@/components/WhiteBack'
import HomeButton from '@/components/HomeButton'

let loadedCount = 0

export default {
  name: 'map',
  components: {
    Background,
    WhiteBack,
    HomeButton
  },
  metaInfo: {
    title: 'マップ | 第73回都立西高校記念祭'
  },
  data() {
    return {
      currentDate: 1,
      urls: [
        ...schoolMap,
        ''
      ]
    }
  },
  methods: {
    onload() {
      loadedCount++
      if (loadedCount >= 1) {
        publish('loaded')
        publish('updateKagikakko', 'kagi-map')
      }
    },
    changeCurrentDate(currentDate) {
      this.currentDate = this.urls.length - 1
      const img = new Image()
      img.src = this.urls[currentDate - 1]
      img.onload = () => {
        this.currentDate = currentDate
      }
    },
    zoom() {
      window.open(this.urls[this.currentDate - 1])
    }
  },
  mounted() {
    // const imageDay1 = new Image()
    // imageDay1.src = this.urls[0]
    // imageDay1.onload = () => {
    //   this.onload()
    // }
    // const imageDay2 = new Image()
    // imageDay2.src = this.urls[1]
    // imageDay2.onload = () => {
    //   this.onload()
    // }
  }
}
</script>

<style scoped>
.home {
  /* background-image: url('../assets/background.png'); */
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.items {
  position: relative;
  transition: top .7s;
  height: 100%;
}

section {
  width: 100vw;
  height: 100%;
  position: absolute;
  box-sizing: border-box;
  text-align: center;
}

section h2 {
  font-size: 50px;
  height: 23%;
  /* line-height: 20vh; */
  font-weight: normal;
  padding: 0;
  margin: 0;
  display: flex;
  align-content: center;
  justify-content: center;
}
section h3 {
  margin: 0;
  margin-top: -20px;
  line-height: 110%;
  font-weight: normal;
}
section article {
  margin: 10px;
  height: calc(77% - 20px);
  box-sizing: border-box;
  width: calc(100% - 20px);
  max-width: none;
  overflow: hidden;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
section h3 span {
  font-weight: bold;
}
section h3 span.inactive-date {
  text-decoration: underline;
  font-weight: normal;
}
img {
  max-height: 100%;
  max-width: 100%;
}
</style>
