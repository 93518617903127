<template>
  <div class="home">
    <Background
      src="https://9p2lla.bn.files.1drv.com/y4mKWvxLEsMKpsGatmL6MSJde3MLLCMX8O0mYzLercZCE1F3J4Ba4ty53Z8FOcnprjQj-cyBm4YearFf8_RrgDzN9KkCzQNL9RUlMiZACXR_4qLLImHvsOHoUcf5z86SD0Mlkm1ZNlB4YbhAmnvs8Zj0Sm-jrJL7KIPHruZYs-MSdv3H8yb7oAXDzJyKxsiTH_fQn4aA1_0eQGCGqozOorNyA?width=1536&height=1125&cropmode=none"
      :y="30"
      :z="-3"
      :opacity="1"
      :onload="onload" />
    <WhiteBack :isActive="true" />
    <div class="items" ref="items">
      <section>
        <h2>Poster</h2>
        <article>
          <img id="main-poster" src="/assets/poster.png" @load="onload">
          <textarea id="answer" type="text" placeholder="西高とは？" v-model="text" />
          <a id="tweet" :href="intent">
            <img id="twitter-img" src="/assets/twitter.png" />
            ツイートする
          </a>
        </article>
      </section>
    </div>
    <HomeButton />
  </div>
</template>

<script>
// @ is an alias to /src
import { publish } from '@/lib/publisher'

import Background from '@/components/Background'
import WhiteBack from '@/components/WhiteBack'
import HomeButton from '@/components/HomeButton'

let loadedCount = 0

export default {
  name: 'main-poster',
  components: {
    Background,
    WhiteBack,
    HomeButton
  },
  metaInfo: {
    title: 'ポスター企画 | 第73回都立西高校記念祭',
    meta: [
      { property: 'og:image', content: 'https://kinensai.netlify.com/assets/poster.png' },
      { property: 'og:title', content: 'あなたの思う西高は？' },
      { property: 'og:description', content: 'ポスターを自分だけのものにして、西高らしさを表してみよう！' }
    ]
  },
  mounted() {
    if (location.href.indexOf('?') > -1) {
      const params = {}
      for (let kv of location.href.split('?')[1].split('&')) {
        params[kv.split('=')[0]] = kv.split('=')[1]
      }
      if (params['text']) {
        this.text = decodeURI(params['text'])
      }
    }
  },
  data() {
    return {
      text: ''
    }
  },
  computed: {
    intent() {
      return 'https://twitter.com/intent/tweet?text=' +
        `${encodeURI(`あなたの思う西高は「${this.text.replace(/\n/g, '')}」`)}` +
        `${encodeURI(' - 都立西高校記念祭')}` +
        `&url=${encodeURI(location.href.split('?')[0] + '?text=' + encodeURI(this.text))}`
    }
  },
  methods: {
    onload() {
      loadedCount++
      if (loadedCount >= 2) {
        publish('loaded')
        publish('updateKagikakko', 'kagi-main-poster')
      }
    }
  }
}
</script>

<style scoped>
.home {
  /* background-image: url('../assets/background.png'); */
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.items {
  position: relative;
  transition: top .7s;
  height: 100%;
}

section {
  width: 100vw;
  height: 100%;
  position: absolute;
  box-sizing: border-box;
  text-align: center;
}

section h2 {
  font-size: 50px;
  height: 23%;
  /* line-height: 20vh; */
  font-weight: normal;
  padding: 0;
  margin: 0;
  display: flex;
  align-content: center;
  justify-content: center;
}
section article {
  text-align: left;
  padding: 0 10px;
  font-family: 'Noto Serif JP';
  max-width: 600px;
  margin: auto;
  height: calc(77% - 10px);
  position: relative;
  display: inline-block;
  overflow: hidden;
  margin-bottom: 10px;
  max-height: 832px;
}
#main-poster {
  height: 100%;
}
#answer {
  position: absolute;
  top: 60%;
  left: 69%;
  width: 18%;
  height: 40%;
  background-color: rgba(0,0,0,0);
  border: none;
  text-align: center;
  font-size: 1.5vh;
  font-family: 'Noto Serif JP';
}
#tweet {
  position: absolute;
  border: 2px solid black;
  background-color: white;
  top: 70%;
  left: 61%;
  height: 5%;
  width: 30%;
  line-height: 3.5vh;
  font-size: 1.5vh;
}
#twitter-img {
  height: 100%;
  float: left;
  padding-right: 10%;
}
</style>
