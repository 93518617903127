<template>
  <section>
    <h2>Food</h2>
    <article @wheel="_prevent" @touchmove="_prevent" ref="article">
      <FoodItem
        v-for="(shopName, index) in Object.keys(foodApi)"
        :key="index"
        :shopName="shopName"
        :foods="foodApi[shopName]"
        :waitInfos="getWaitTime(shopName)">
      </FoodItem>
    </article>
  </section>
</template>

<script>
import FoodItem from '@/components/FoodItem.vue'

export default {
  components: {
    FoodItem
  },
  props: {
    prevent: Function
  },
  data() {
    return {
      foodApi: {},
      foodWaits: {}
    }
  },
  mounted() {
    const xhr = new XMLHttpRequest()
    xhr.open('GET', '/food.json')
    xhr.onerror = err => {
      console.error(err)
    }
    xhr.onload = () => {
      this.foodApi = {}
      const foodArray = JSON.parse(xhr.response)
      for (let foodItem of foodArray) {
        if (typeof this.foodApi[foodItem.shop] === 'undefined') {
          this.foodApi[foodItem.shop] = []
        }
        this.foodApi[foodItem.shop].push(foodItem)
      }
      console.log(this.foodApi)
    }
    xhr.send()

    const xhr2 = new XMLHttpRequest()
    xhr2.open('GET', 'https://kinensai-machi.herokuapp.com/getFoodMachi')
    xhr2.onerror = err => {
      console.error(err)
    }
    xhr2.onload = () => {
      this.foodWaits = JSON.parse(xhr2.response)
    }
    xhr2.send()
  },
  methods: {
    _prevent(event) {
      this.prevent(event, this.$refs.article)
    },
    getWaitTime(org) {
      const result = {}
      for (let waitInfo in this.foodWaits) {
        const splited = waitInfo.split('@')
        if (splited[0] === org) {
          result[splited[1]] = this.foodWaits[waitInfo]
        }
      }
      console.log(org, result)
      return result
    }
  }
}
</script>

<style scoped>
section {
  position: absolute;
  top: 500%;
}
article {
  text-align: center;
  max-width: none;
}
</style>
