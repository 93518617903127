<template>
  <div>
    <h4>「{{ shopName }}」</h4>
    <p class="description">
      <ul>
        <li v-for="item in foods">
          {{ item.name }}
          &nbsp;
          {{ item.price }}円
          <br />
          <span v-if="getFood(item.name) !== null">
            待ち時間: {{ getFood(item.name).waitTime }}分
            &nbsp;
            <!-- 残り個数: {{ getFood(item.name).itemsLeft }}個
            &nbsp; -->
            ({{ Math.floor((Date.now() - parseInt(getFood(item.name).timestamp)) / 1000 / 60)}}分前に更新)
          </span>
        </li>
      </ul>
    </p>
  </div>
</template>

<script>
import schoolMap from '@/lib/schoolMap'

export default {
  props: {
    shopName: String,
    foods: Array,
    waitInfos: Object
  },
  methods: {
    getFood(foodName) {
      if (this.waitInfos[foodName]) {
        return this.waitInfos[foodName]
      }
      return null
    }
  }
}
</script>

<style scoped>
div {
  max-width: 600px;
  margin: 0 auto;
}
h4 {
  font-weight: normal;
  font-size: 20px;
  border-bottom: 1px solid black;
  margin: 0;
}
.subtitle {
  margin: 0;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.subtitle p {
  margin: 0;
  white-space: nowrap;
}
.description {
  text-align: left;
  margin: 10px 10px 40px 10px;
}
a {
  text-decoration: underline;
}
</style>
